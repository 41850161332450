export interface AddressI {
    _id?:             string;
    deleted?:         boolean;
    deletedAt?:       null;
    desc1?:           string;
    provinceCode?:    number;
    provinceName?:    string;
    districtCode?:    number;
    districtName?:    string;
    subDistrictCode?: number;
    subDistrictName?: string;
    zipCode?:         number;
    isSelected?:      boolean;
    createdAt?:       Date;
    updatedAt?:       Date;
}

export const concatAddress = (address: AddressI): string => {
	return `${address?.desc1 || ''} ${address?.subDistrictName || ''} ${address?.districtName || ''} ${address?.provinceName || ''} ${address?.zipCode || ''}`
}