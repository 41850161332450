export const phoneTh = (value: string): boolean => value.startsWith('06') || value.startsWith('08') || value.startsWith('09')

export const isIOS = (): boolean => {
	if (!navigator?.userAgent) {
		return false
	}
	return /iPad|iPhone|iPod/.test(navigator?.userAgent)
}

export const isAndroid = (): boolean => {
	if (!navigator?.userAgent) {
		return false
	}
	return /Android/i.test(navigator?.userAgent)
}
